'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.devices.controller:DevicesCtrl

 # @description

###
class DevicesCtrl
  ### @ngInject ###
  constructor:(
    $state,
    $scope
  ) ->
    @currentTab = $state.current.data.selectedTab

    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) =>
      @currentTab = toState.data.selectedTab
angular
  .module('mundoAdmin.devices')
  .controller 'DevicesCtrl', DevicesCtrl
